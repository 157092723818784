/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';
import 'zone.js'; // Included with Angular CLI.

interface CustomWindow {
  process: { env: { DEBUG: undefined; NODE_DEBUG: boolean } };
  global: Window;
}

(window as unknown as CustomWindow).process = {
  env: { DEBUG: undefined, NODE_DEBUG: false },
};

(window as unknown as CustomWindow).global = window;
